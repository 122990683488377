@import '~@angular/material/theming';
@include mat-core();


/* ======== Angular material custom themes ======== */
//light theme
$my-custom-primary: mat-palette($mat-indigo, 500);
$my-custom-accent: mat-palette($mat-grey, 200);
$my-custom-warn: mat-palette($mat-red, 500);
$my-custom-icon: mat-palette($mat-green, 500);


//dark theme
$my-dark-primary: mat-palette($mat-indigo, A200);
$my-dark-accent: mat-palette($mat-grey, 400);
$my-dark-warn: mat-palette($mat-red, 400);
$my-dark-icon: mat-palette($mat-green, A400);

$my-custom-theme: mat-light-theme($my-custom-primary, $my-custom-accent, $my-custom-warn);
$my-dark-theme: mat-dark-theme($my-dark-primary, $my-dark-accent, $my-dark-warn);
@include angular-material-theme($my-custom-theme);

.dark-theme {
    @include angular-material-theme($my-dark-theme);

    .mat-menu-panel {
        background: #424242;
        color: #FFFFFF
    }

    .card-selected {
        border: 2px solid #536dfe;
    }
}

.light-theme {
    .card-selected {
        border: 2px solid #283593;
    }
}