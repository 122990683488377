/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// @import "app/theme/styles/base";

// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
@import "app/theme/theme";



.active {
  font-size: 55px;
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    
}


.gap-left {
    margin-left: 11px;
}

/* hr.solid-separator {
    border-top: 3px solid #bbb;
} */

.mat-card {
    margin-bottom: 2px !important;
}

.mat-card-actions {
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  padding-bottom: 0px !important;
}

.mat-card-content{
  margin-bottom: 5px !important;
}

.mat-button-base {
    margin-right: 5px; //!important;
}
/* 
.iconos {
    color: #5f5f5f;
  } */

  /* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fafafa; 
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c5c5c5; 
    border-radius: 5px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #c5c5c5; 
} */

svg#freepik_stories-no-data:not(.animated) .animable {
    opacity: 0;
  }
  svg#freepik_stories-no-data.animated #freepik--Device--inject-1 {
    animation: 6s Infinite linear heartbeat;
    animation-delay: 0s;
  }
  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(1.1);
    }
    30% {
      transform: scale(1);
    }
    40% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    60% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  